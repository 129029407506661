<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>
      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="政策实施情况"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <el-row>
      <!-- 纵向表头设计 -->
      <el-col :span="5" style="background-color: #F9FAFE;">
        <el-row>
          <div class="leftTitletStyle">操作</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">指标</div>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view1">
            <div class="firstTitletStyle">
              工程建设及移民基本情况
            </div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">纳入后扶范围</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">合计（座）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">枢纽工程所在地（座）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">仅库区所在地（座）</div>
                </el-row>
              </el-col>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">移民涉及县、区、市（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">移民乡、镇、街道（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">移民村、社区（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">移民组（个）</div>
            </el-row>
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">后期扶持人数</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">合计（人）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">直发直补（人）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">项目扶持（人）</div>
                </el-row>
              </el-col>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">移民人均可支配收入（元）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">脱贫人口（人）</div>
            </el-row>
          </el-col>
        </el-row>

        <!-- 机构设置 -->
        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">机构设置</div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">公务员编制（人）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">事业单位编制（人）</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <!-- 配套政策法规和制度 -->
        <el-row>
          <el-col :span="4" class="title_view3">
            <div class="firstTitletStyle">配套政策法规和制度</div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">市级（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">县级（个）</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <!-- 规划情况 -->
        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">规划情况</div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8">
                <div class="leftTitletStyle">后服规划</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">编审（个）</div>
                </el-row>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="leftTitletStyle">发展规划</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">实际编审（个）</div>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <!-- 后期扶持 -->
        <el-row>
          <el-col :span="4" class="title_view1">
            <div class="firstTitletStyle">后期扶持相关资金使用管理情况</div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">合计</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">收入（万元）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">支出（万元）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle firstTitletStyle">600元后扶资金</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="8" class="title_view2">
                    <div class="leftTitletStyle firstTitletStyle">小计</div>
                  </el-col>
                  <el-col :span="16">
                    <el-row>
                      <div class="leftTitletStyle">收入（万元）</div>
                    </el-row>
                    <el-row>
                      <div class="leftTitletStyle ">支出（万元）</div>
                    </el-row>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="8" class="title_view2">
                    <div class="firstTitletStyle firstTitletStyle">直发直补</div>
                  </el-col>
                  <el-col :span="16">
                    <el-row>
                      <div class="leftTitletStyle">收入（万元）</div>
                    </el-row>
                    <el-row>
                      <div class="leftTitletStyle">支出（万元）</div>
                    </el-row>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="8" class="title_view2">
                    <div class="leftTitletStyle firstTitletStyle">项目扶持</div>
                  </el-col>
                  <el-col :span="16">
                    <el-row>
                      <div class="leftTitletStyle">收入（万元）</div>
                    </el-row>
                    <el-row>
                      <div class="leftTitletStyle">支出（万元）</div>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">项目资金</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col>
                    <div class="leftTitletStyle">收入（万元）</div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col>
                    <div class="leftTitletStyle">收入（万元）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">其他资金（专项工资经费）</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col>
                    <div class="leftTitletStyle">收入（万元）</div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col>
                    <div class="leftTitletStyle">收入（万元）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="2" style="background-color: #F9FAFE;">
        <el-row>
          <div class="leftTotalStyle">合计</div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('locationTotal') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('hubLocation') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('warehouseLocation') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('immigrantCounties') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('immigrantTowns') }}</div>
          </div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('immigrantCommunitys') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('immigrantGroups') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('directProjectTotal') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('directAid') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('projectSupport') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('perCapitaIncome') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('povertyPopulation') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('civilServants') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('publicInstitutionStaff') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('cityLevelStaff') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('countyLevelStaff') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('postAidPlanningReview') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('developmentPlanningActualReview') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('projectAllIncomeTotal') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('projectAllExpenditureTotal') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('directProjectIncomeTotal') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('directProjectExpenditureTotal') }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('directAidIncome') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('directAidExpenditure') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('projectSupportIncome') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('projectSupportExpenditure') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('projectFundsIncome') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('projectFundsExpenditure') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('otherFundsIncome') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('otherFundsExpenditure') }}</div>
            </div>
          </el-col>
        </el-row>
      </el-col>

      <!-- 2.纵向数据展示 -->
      <el-col :span="17">
        <div class="main_box">
          <!-- -纵列数据遍历 -->
          <div :style="boxWidth" class="clearFloat">
            <div>
              <el-row class="flex">
                <el-row style="width: 150px;" v-for="(col, index) in listData" :key="col.id">
                  <el-col :span="24">
                    <el-row v-if="type == 'add'">
                      <div class="leftTitletStyle">
                        <el-link type="danger" @click="removeCol(index)">删除</el-link>
                      </div>
                    </el-row>
                    <el-row v-if="type != 'add'">
                      <div class="leftTitletStyle"></div>
                    </el-row>

                    <el-col>
                      <template v-for="key1 in Object.keys(col)">
                        <el-row v-if="key1 == 'countyId'">
                          <div class="contentStyle">
                            <el-cascader  :append-to-body="false"  
                              v-if="type == 'add' || type == 'edit'"
                              v-model="col[key1]"
                              size="mini"
                              :options="levalRegion"
                              :props="treeProps"
                              @change="handleChange($event, index)"
                              :show-all-levels="false"
                              placeholder="请选择"
                            >
                            </el-cascader>
                            <div class="box_view" v-else>
                              {{ col['county'] }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'locationTotal'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{ (Number(col['hubLocation']) * 1000 + Number(col['warehouseLocation']) * 1000) / 1000 }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'hubLocation'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'warehouseLocation'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'immigrantCounties'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'immigrantTowns'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'immigrantCommunitys'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'immigrantGroups'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'directProjectTotal'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{ (Number(col['directAid']) * 1000 + Number(col['projectSupport']) * 1000) / 1000 }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'directAid'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'projectSupport'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'perCapitaIncome'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input size="mini" type="number" min="0" v-model="col[key1]" placeholder="请输入">
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'povertyPopulation'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'civilServants'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'publicInstitutionStaff'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'cityLevelStaff'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'countyLevelStaff'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'postAidPlanningReview'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'developmentPlanningActualReview'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'projectAllIncomeTotal'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{
                                (Number(col['directAidIncome']) * 1000 +
                                  Number(col['projectSupportIncome']) * 1000 +
                                  Number(col['projectFundsIncome']) * 1000 +
                                  Number(col['otherFundsIncome']) * 1000) /
                                  1000
                              }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'projectAllExpenditureTotal'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{
                                (Number(col['directAidExpenditure']) * 1000 +
                                  Number(col['projectSupportExpenditure']) * 1000 +
                                  Number(col['projectFundsExpenditure']) * 1000 +
                                  Number(col['otherFundsExpenditure']) * 1000) /
                                  1000
                              }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'directProjectIncomeTotal'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{
                                (Number(col['directAidIncome']) * 1000 + Number(col['projectSupportIncome']) * 1000) /
                                  1000
                              }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'directProjectExpenditureTotal'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{
                                (Number(col['directAidExpenditure']) * 1000 +
                                  Number(col['projectSupportExpenditure']) * 1000) /
                                  1000
                              }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'directAidIncome'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'directAidExpenditure'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'projectSupportIncome'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'projectSupportExpenditure'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'projectFundsIncome'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'projectFundsExpenditure'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'otherFundsIncome'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'otherFundsExpenditure'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-input
                              size="mini"
                              oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                          </div>

                          <div v-else class="contentStyle">
                            <div class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>
                      </template>
                    </el-col>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'

import { _getLevelRegion } from '@/api/keepHome'
import {
  _ImplementationAdd,
  _ImplementationInfo,
  _ImplementationEdit
} from '@/api/modular/postImmigrationService/policy/basicSituation.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      value: '2021年度',
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      levalRegion: [], //成都市
      treeProps: { label: 'name', value: 'id', children: 'childRegions', pid: 'pids', checkStrictly: true }
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 150 * this.listData.length
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    calculateTheTotal(name) {
      let sum = 0
      if (name === 'locationTotal') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += (Number(ele['hubLocation']) * 100 + Number(ele['warehouseLocation']) * 100) / 100
        }
      } else if (name === 'directProjectTotal') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += (Number(ele['directAid']) * 100 + Number(ele['projectSupport']) * 100) / 100
        }
      } else if (name === 'projectAllIncomeTotal') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum +=
            (Number(ele['directAidIncome']) * 1000 +
              Number(ele['projectSupportIncome']) * 1000 +
              Number(ele['projectFundsIncome']) * 1000 +
              Number(ele['otherFundsIncome']) * 1000) /
            100
        }
      } else if (name === 'projectAllExpenditureTotal') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum +=
            (Number(ele['directAidExpenditure']) * 1000 +
              Number(ele['projectSupportExpenditure']) * 1000 +
              Number(ele['projectFundsExpenditure']) * 1000 +
              Number(ele['otherFundsExpenditure']) * 1000) /
            100
        }
      } else if (name === 'directProjectIncomeTotal') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += (Number(ele['directAidIncome']) * 1000 + Number(ele['projectSupportIncome']) * 1000) / 1000
        }
      } else if (name === 'directProjectExpenditureTotal') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += (Number(ele['directAidExpenditure']) * 1000 + Number(ele['projectSupportExpenditure']) * 1000) / 1000
        }
      } else {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += Number(ele[name])
        }
      }

      return sum
    },
    add(type, row) {
      this.type = type
      this.row = row
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row

      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },

    editCols() {
      this.type = 'edit'
      this.title = '编辑'
    },

    btnClose() {
      this.listData = []
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: null, //县（市区）
            county: '', //县（市区）
            locationTotal: null,
            hubLocation: null, //枢纽工程所在地（座）
            warehouseLocation: null, //仅库区所在地（座）
            immigrantCounties: null, //移民涉及县（个）
            immigrantTowns: null, //移民乡（个）
            immigrantCommunitys: null, //移民村（个）
            immigrantGroups: null, //移民组（个）
            directProjectTotal: null,
            directAid: null, //直发直补（人）
            projectSupport: null, //项目扶持（人）
            perCapitaIncome: null, //移民人均可支配收入（元）
            povertyPopulation: null, //脱贫人口（人)
            civilServants: null, //公务员编制（人）
            publicInstitutionStaff: null, //事业单位编制（人）
            cityLevelStaff: null, //市级（人）
            countyLevelStaff: null, //县级（人）
            postAidPlanningReview: null, //后扶规划编审（个）
            developmentPlanningActualReview: null, //发展规划实际编审（个）
            projectAllIncomeTotal: null, //后期扶持合计（收入）
            projectAllExpenditureTotal: null, //后期扶持合计（支出）
            directProjectIncomeTotal: null, //600元小计（收入）
            directProjectExpenditureTotal: null, //600元小计（支出）
            directAidIncome: null, //直发直补收入（万元）
            directAidExpenditure: null, //直发直补支出（万元）
            projectSupportIncome: null, //项目扶持收入（万元）
            projectSupportExpenditure: null, //项目扶持支出（万元）
            projectFundsIncome: null, //项目资金收入（万元）
            projectFundsExpenditure: null, //项目资金支出（万元）
            otherFundsIncome: null, //其他资金（专项工作经费）收入（万元）
            otherFundsExpenditure: null, //其他资金（专项工作经费）支出（万元）
            countyPids: ''
          }
          if (this.listData.length) {
            let flag = this.listData.every(ele => ele.countyId)
            if (flag) {
              this.listData.splice(this.listData.length, 0, obj)
            } else {
              this.$message.error('请选择县（市区）')
            }
          } else {
            this.listData.splice(this.listData.length, 0, obj)
          }
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    submitForm() {
      let flag = this.listData.every(ele => ele.countyId)

      console.log(this.listData, '=====提交的数据====')
      switch (this.type) {
        case 'add':
          if (flag) {
            _ImplementationAdd(this.listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
        case 'edit':
          if (flag) {
            _ImplementationEdit(this.listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
      }
    },

    getBasicInfo() {
      let params = {
        id: this.row.id
      }

      _ImplementationInfo(params).then(res => {
        if (res.code == 200) {
          let arr = [res.data]
          this.listData = this.formateResData(arr)
        }
      })
    },
    formateResData(arr) {
      return arr.map(ele => {
        return {
          year: ele.year, //年份
          countyId: ele.countyId, //县（市区）
          county: ele.county, //县（市区）
          locationTotal: ele.locationTotal,
          hubLocation: ele.hubLocation, //枢纽工程所在地（座）
          warehouseLocation: ele.warehouseLocation, //仅库区所在地（座）
          immigrantCounties: ele.immigrantCounties, //移民涉及县（个）
          immigrantTowns: ele.immigrantTowns, //移民乡（个）
          immigrantCommunitys: ele.immigrantCommunitys, //移民村（个）
          immigrantGroups: ele.immigrantGroups, //移民组（个）
          directProjectTotal: 0,
          directAid: ele.directAid, //直发直补（人）
          projectSupport: ele.projectSupport, //项目扶持（人）
          perCapitaIncome: ele.perCapitaIncome, //移民人均可支配收入（元）
          povertyPopulation: ele.povertyPopulation, //脱贫人口（人)
          civilServants: ele.civilServants, //公务员编制（人）
          publicInstitutionStaff: ele.publicInstitutionStaff, //事业单位编制（人）
          cityLevelStaff: ele.cityLevelStaff, //市级（人）
          countyLevelStaff: ele.countyLevelStaff, //县级（人）
          postAidPlanningReview: ele.postAidPlanningReview, //后扶规划编审（个）
          developmentPlanningActualReview: ele.developmentPlanningActualReview, //发展规划实际编审（个）
          projectAllIncomeTotal: 0, //后期扶持合计（收入）
          projectAllExpenditureTotal: 0, //后期扶持合计（支出）
          directProjectIncomeTotal: 0, //600元小计（收入）
          directProjectExpenditureTotal: 0, //600元小计（支出）
          directAidIncome: ele.directAidIncome, //直发直补收入（万元）
          directAidExpenditure: ele.directAidExpenditure, //直发直补支出（万元）
          projectSupportIncome: ele.projectSupportIncome, //项目扶持收入（万元）
          projectSupportExpenditure: ele.projectSupportExpenditure, //项目扶持支出（万元）
          projectFundsIncome: ele.projectFundsIncome, //项目资金收入（万元）
          projectFundsExpenditure: ele.projectFundsExpenditure, //项目资金支出（万元）
          otherFundsIncome: ele.otherFundsIncome, //其他资金（专项工作经费）收入（万元）
          otherFundsExpenditure: ele.otherFundsExpenditure, //其他资金（专项工作经费）支出（万元）
          countyPids: ele.countyPids,
          id: ele.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';
</style>
